<template>
<div>
  <Header></Header>
  <TagList :tags="tags"></TagList>
  <div class="blog">
    <div class="author">{{ author }}</div>
    <router-view :key="this.$route.name"/>
    <router-link to="/" tag="a" class="back">&laquo; Back</router-link>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import TagList from '../components/TagList.vue'
import BLOGENTRIES from '@/statics/data/blogs.json'
import Prism from 'prismjs'

import 'prismjs/components/prism-c'
import 'prismjs/components/prism-cpp'
import 'prismjs/components/prism-scss'

// import 'prismjs/themes/prism.css'
import '@/scss/theme.css'

export default {
  name: 'Home',
  metaInfo: {
      title: 'Blog.',
      htmlAttrs: {
      lang: 'en',
      amp: true
    },
    base: { 
      target: '_self', 
      href: '/' 
    },
    meta: [
      { title: 'Blog Post'},
      { charset: 'utf-8' },
      { 
        name: 'viewport', 
        content: 'width=device-width, initial-scale=1' 
      },
      {
        vmid: 'description',
        name: 'description',
        content: 'The blog site of Yudishthir Lokhande. Read about an array of topics like Food, Tech, Travel, Dev and many more. I just write what I feel.'
      },
      {
        name: 'keywords',
        content: "Yudishthir Lokhande, Yudishthir, Lokhande, Blog, MIT, Manipal, Yudi Lokhande, Yudi Lokhande Blog, Yudishthir Lokhande Blog"
      }
    ]
    },
  components: {
    Header,
    Footer,
    TagList
  },
  data () {
    return {
      tags: [],
      author: ""
    }
  },
  mounted() {
    Prism.highlightAll()
    window.scrollTo({ top: 0});
  },
  watch:{
    $route (){
      window.scrollTo({ top: 0});
      this.tags = [];
      let id = this.$route.name;
      Object.keys(BLOGENTRIES).forEach(element => {
        BLOGENTRIES[element].forEach(obj => {
          if(obj.id == id) {
            this.author = obj.author;
            obj.tags.forEach(tag => {
              tag = tag[0].toUpperCase() + tag.substring(1,);
              this.tags.push(tag);
            })
          }
        })
      })
      this.$nextTick(()=> Prism.highlightAll());
    }
  },
  created () {
    window.scrollTo({ top: 0});
    this.tags = [];
    let id = this.$route.name;
    Object.keys(BLOGENTRIES).forEach(element => {
      BLOGENTRIES[element].forEach(obj => {
        if(obj.id == id) {
          this.author = obj.author;
          obj.tags.forEach(tag => {
            tag = tag[0].toUpperCase() + tag.substring(1,);
            this.tags.push(tag);
          })
        }
      })
    })
  }
}
</script>


<style lang="scss" scoped>
@import "../scss/variables.scss";

.blog {
  width: 90vw;
  margin: 1rem auto;
  font-family: "Fira Sans",aRoboto,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-size: 20px;
  line-height: 1.5;

  .author {
    padding-top: 1rem;
    font-size: 1rem;
    color: $--text-secondary;
    margin-bottom: -0.6rem;
  }

  .back {
    background-color: #42b883;
    color: white;
    margin-top: 2.5rem;
    text-decoration: none;
    padding: 10px 15px;
    border: 1px solid #42b883;
    border-radius: .5rem;
    display: inline-block;
    transition: all .3s ease;
    &:hover {
      background-color: transparent;
      color: #42b883;
    }
  }

  /deep/ {
    hr {
      max-width: 30vw;
      margin-bottom: 1.5rem;
      margin-top: 0.5rem;
      border: 1px solid #00acc1;
      background-color: #00acc1;
    }
    h1 {
      font-size: 3rem;
      color: $--text-primary;
    }
    h2 {
      margin: 1.5rem 0;
        
        code {
          overflow-x: auto;
          background-color: #35495e;
          border-radius: .3rem;
          padding: 0 0.2rem;
          font-family: monospace;
        }
      
    }
    h4 {
      margin: 1.5rem 0;
      color: #35495e;
    }
    pre {
      overflow-x: auto;
      // background-color: #dddddd;
      border-radius: .3rem;
      padding: 1rem;
      margin: 2rem 0;
      code {
        font-family: monospace;
        // text-shadow: #dddddd 2px 2px;
        font-size: 15px;
      }
    }
    img {
      max-width: 100%;
    }
    p {
      margin-bottom: 2rem;
      code {
        overflow-x: auto;
        border-radius: .3rem;
        font-size: 17px;
        padding: 3.4px 6.8px;
      }
    }
    h3 {
      margin: 1.5rem 0;
      font-size: 25px;
    }
    ul {
      margin: 0 0 2rem 1.2rem;
      list-style: none;

			li::before {
				display: inline-block;
				width: 1em;
				content: "\2022";
				color: #00acc1;
				font-size: 1.2rem;
				font-weight: bold;
        margin-left: -1.1em;
        margin-top: 0.7rem;
        word-wrap: break-word;
      }
      li {
        code {
        overflow-x: auto;
        border-radius: .3rem;
        font-size: 17px;
        padding: 3.4px 6.8px;
      }
      }
    }
    ol {

      margin: 0 0 2rem 1.2rem;
			counter-reset: item;

			li {
				display: block;
        margin-top: 0.7rem;
        code {
        overflow-x: auto;
        border-radius: .3rem;
        font-size: 17px;
        padding: 3.4px 6.8px;
      }
			}

			li:before {
				content: counter(item) ". ";
				counter-increment: item;
				color: $--text-primary;
				margin-left: -1.1em;
			}      
    }
    a {
      color: #00acc1;
      text-decoration: none;
      word-wrap: break-word;
    }
    img {
      display: block;
      margin: auto;
    }
  }
}

@media only screen and (min-width: 768px) {
  .blog, #header {
    width: 800px;
  }
  
  #footer {
      display: flex;
      justify-content: space-around;

      ul {
        margin: 0;
      }
  }

  .blog {
    padding: 0 1.5rem 0 1.5rem
  }

    /deep/
    pre {
      width: 700px;
      display: block;
      margin: 2rem auto !important;
    }
}


</style>