<template>
    <div id="taglist"> 
        <ul id="list">
            <li v-for="tag in tags" :key="tag">{{ tag }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "TagList",
    props: ['tags']
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

#taglist {
    width: 90vw;
    max-width: 800px;
    margin: 3rem auto 0 auto;
    // padding-left: 1.2rem;

    #list {
        display: flex;
        list-style: none !important;
        font-family: "Fira Sans",aRoboto,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
        font-size: 18px;

        li {
            display: inline;
            border: 1px solid #00acc1;
            padding: 0.3rem 0.6rem;
            border-radius: 15px;
            color: #00acc1;
            // background-color: #00acc1;
            margin-right: 0.4rem;
        }
    }
}

@media only screen and (min-width: 768px) {
    #taglist {
        margin-top: 4rem;
        width: 800px;
        padding-left: 1.3rem;
    }

}

</style>